<template>
  <div class="">
    <div class="row justify-content-end">
      <div class="col-auto">
        <button
          @click="refresh()"
          class="btn btn-secondary"
        >
          <i class="bi bi-plus-square"></i>
          Actualiser
        </button>
      </div>
      <div class="col-auto">
        <button
          @click="$router.push({ name: 'vehicles-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square"></i>
          Créer
        </button>
      </div>
    </div>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence Nº</th>
          <th scope="col">Numéro d’immatriculation</th>
          <th scope="col">Marque</th>
          <th scope="col">Modèle</th>
          <th scope="col">Année de fabrication</th>
          <th scope="col">Créer par</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(vehicle, index) in vehicles" :key="index++">
          <td>
            {{ index }}
          </td>
          <td class="p-0 m-0">
            {{ vehicle.reference }}
          </td>
          <td>
            {{ vehicle.registrationPlate }}
          </td>
          <td>{{ vehicle.manufacturerName }}</td>
          <td>
            {{ vehicle.modelName }}
          </td>
          <td>
            {{ vehicle.yearManufacture }}
          </td>

          <td>
            <span v-if="vehicle.user">
              {{ vehicle.user.name }}
            </span>
          </td>
          <td>
            <button
              class="btn"
              @click="
                $router.push({
                  name: 'vehicles-details',
                  params: { reference: vehicle.reference },
                })
              "
            >
              Details
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
    };
  },
  computed: {
    ...mapGetters("vehicle", {
      vehicles: "getVehicles",
    }),
  },
  mounted() {
    this.$store.dispatch("vehicle/getAll");
  },
  methods: {
    async search(value) {
      return await this.$store.commit("vehicle/search", value);
    },
    async refresh() {
      return await this.$store.dispatch("vehicle/getAll");
    },

    async listVehicles(checkbox) {
      if (checkbox == true) {
        console.log(checkbox);
        return await this.$store.commit("vehicle/getTodays");
      } else {
        return await this.$store.dispatch("vehicle/getAll");
      }
    },
    async sort(value) {
      return await this.$store.commit("vehicle/sort", value);
    },
   
  },
};
</script>
